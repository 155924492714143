import React from 'react'
import { Seo, UpgradeBrowserPage } from '@klickmarketing/react-components'

import { useSiteMetadata } from '../hooks/useSiteMetadata'

const UpgradeBrowser = () => {
  const siteMetadata = useSiteMetadata()

  return (
    <>
      <Seo {...siteMetadata} title="Upgrade Browser" />
      <UpgradeBrowserPage />
    </>
  )
}

export default UpgradeBrowser
